<!-- 批量发放卡券 -->
<!--卡券列表-->
<template>
  <div class="batch">
    <section class="table-search">
      <div class="search-L">
        <el-select size="medium" v-model="tableData" value-key="memberId" multiple filterable remote collapse-tags
          reserve-keyword placeholder="请输入用户的昵称/手机号搜索选择" :remote-method="remoteMethod" :loading="loading" class="mr15"
          @change="change">
          <el-option v-for="item in options" :key="item.memberId" :label="item.name" :value="item">
          </el-option>
        </el-select>
        <el-button type="primary" @click="importExcel" style="z-index: 999;">导入表格</el-button>

      </div>
      <div class="search-R">
        <Records />
      </div>
    </section>
    <div v-if="tableData.length == 0" class="empty">
      <img src="@/assets/images/layout/empty.png" alt="" style="width: 400px; height: 259px" />
      <p>数据为空，请先选择或导入用户～</p>
    </div>
    <div v-else class="table-box">
      <el-button type="primary" plain @click="delAllTag">一键清除已选用户</el-button>
      <div class="tag_box">
        <div class="tag_item" v-for="(item, index) in tableData" :key="index">
          <div class="tag">
            {{ item.name }}
          </div>
          <div class="close" @click="delTag(index)">
            <i class="el-icon-error" style="color: #999"></i>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button type="primary" @click="send()">批量发卡券（{{ this.tableData.length }}个用户）</el-button>
      </div>
      <el-dialog title="批量发放卡券" :visible.sync="sendDialog" :close-on-click-modal="false" center width="80%">

        <el-form :model="sendForm">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="适用门店" label-width="120px">
                <el-input v-model="cardParams.shopName" autocomplete="off" maxlength="20" disabled
                  class="mr15"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="服务内容" label-width="120px">
                <el-select v-model="cardParams.serviceId" placeholder="请选择服务内容" @change="_getCardList" clearable
                  class="mr15">
                  <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                    :value="item.serviceId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卡券类型" label-width="120px">
                <el-select v-model="cardParams.cardType" placeholder="请选择卡券类型" @change="reload">
                  <el-option v-for="item in ObjToOpt(cardTypeObj)" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>



          <el-form-item label="卡券列表" label-width="120px">
            <div class="coupon">
              <div class="coupon_item" :class="{ selected: selectedCoupon == index }"
                v-for="(item, index) in couponList" :key="index" @click="selectedCard(index)">
                {{ item.cardName }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="发放事由" label-width="120px">
            <el-input v-model="sendForm.giveReason" autocomplete="off" placeholder="输入 200 字以内发放事由" maxlength="200"
              show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sendCoupon">确认发放（{{ this.tableData.length }}个用户）</el-button>
        </div>
      </el-dialog>

    </div>
    <!-- 表格 -->
    <Upload :fileType="7" ref="up" @getUserList="reciveUserList" :action="'/apm/ums/shop/user/import/user/list'"
      :listType="'text'" />
  </div>
</template>
<script>
import Upload from "@/components/Import";
import {
  getUserList,
  getUserListExport,
} from "@/api/user/user";
import {
  getCardList,
} from "@/api/group/card";
import { getTableList, batchSend } from "@/api/card/card";
import {
  getServiceList,
} from "@/api/resource/room";
import { ObjToOpt } from "@/utils/utils";
import { cardTypeObj } from "@/db/objs";
import Records from "./components/records.vue";
export default {
  components: {
    Records,
    Upload,
  },
  data() {
    return {
      ObjToOpt,
      cardTypeObj,
      tableData: [],
      sendForm: {},
      options: [],
      loading: false,
      sendDialog: false,
      list: [],
      couponList: [],
      selectedCoupon: 0,
      queryParams: {
        page: 1,
        pageSize: 0,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      serviceList: [],
      cardParams: {
        cardType: 1,
        page: 1,
        size: 0,
        merchantId: "", //商户ID
        shopId: "", //门店ID
        shopName: "", //门店ID
        serviceId: "",
      },
    };
  },
  watch: {
    // 监听搜索框kw变化
    tableData(val) {
      // 搜索
      this.sendForm.memberIdSet = val.map((item) => {
        return item.memberId;
      });
    },
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.queryParams.merchantId = JSON.parse(merchantShopId)[0];
      this.queryParams.shopId = JSON.parse(merchantShopId)[1];
    }
    let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
    if (merchantShopInfo) {
      this.cardParams.merchantId = merchantShopInfo.merchantId;
      this.cardParams.shopId = merchantShopInfo.shopId;
      this.cardParams.shopName = merchantShopInfo.shopName;
      this.sendForm.merchantId = merchantShopInfo.merchantId;
      this.sendForm.shopId = merchantShopInfo.shopId;
      this.sendForm.shopName = merchantShopInfo.shopName;
    }
  },
  mounted() {



  },
  methods: {
    reset() {
      this.tableData = []
      this.sendForm.giveReason = ''
    },
    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.cardParams.shopId
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
          this.cardParams.serviceId = this.serviceList[0].serviceId;
          this._getCardList(); // 【请求】表格数据

        }
      });
    },
    // 添加用户
    send() {
      this.sendDialog = true;
      this.getServiceList();
    },
    // 选择卡券
    selectedCard(index) {
      this.selectedCoupon = index;
      this.sendForm.goodsId = this.couponList[index].cardId;
    },

    // 重新加载卡券列表
    reload() {
      this.cardParams.page = 1;
      this._getCardList();
    },

    // 点击确认发放
    sendCoupon: _.debounce(function () {
      batchSend(this.sendForm).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message.success("发放成功");
          this.reset();
          this.sendDialog = false;
        }
      });
    }, 500),
    // 远程搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.queryParams.kw = query;
        getUserList(this.queryParams).then((res) => {
          this.loading = false;
          this.options = res.data.list.map((item) => {
            return {
              name: `${item.nickName + " | " + item.userMobile}`,
              memberId: `${item.memberId}`,
            };
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    // 【请求】卡券列表
    _getCardList() {
      getCardList(this.cardParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.couponList = res.data;
          this.sendForm.goodsId = this.couponList[0].cardId;
        }
      });
    },
    // 点击删除当前tag
    delTag(index) {
      this.tableData.splice(index, 1);
    },
    // 一键清除已选用户
    delAllTag() {
      this.tableData = [];
    },
    // 搜索框变化
    change(e) {
      this.tableData = e;
    },


    // 返回上一页
    onBack() {
      this.$router.go(-1);
    },
    // 导入表格
    importExcel() {
      this.$refs.up.onUpload();
    },
    reciveUserList(e) {
      let arr = []
      e.forEach(element => {
        arr.push({
          memberId: element.memberId,
          name: element.nickName + ' | ' + element.userMobile
        })
      });
      this.tableData = arr
    }

  },
};
</script>
<style lang="scss" scoped>
.batch {
  padding: 0.15rem;
  box-sizing: border-box;

  /deep/.el-form-item__content {
    width: 80% !important;
  }
}

.table-search {
  display: flex;

  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
  width: 300px;

  /deep/.el-input__inner {
    width: 300px !important;
  }
}

.search-R {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);

  p {
    margin-top: 0.15rem;
  }
}

.table-box {
  height: calc(100vh - 200px);

  .tag_box {
    padding: 0.15rem 0;
    height: calc(100% - 150px);
    overflow-y: auto;
    margin-top: 0.15rem;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;

    .tag_item {
      position: relative;

      height: 36px;
      margin-bottom: 20px;
      margin-right: 85px;

      .close {
        position: absolute;
        right: -4px;
        top: -4px;
      }

      .tag {
        padding: 0 20px;
        height: 100%;
        line-height: 36px;
        text-align: center;
        background: rgba(153, 153, 153, 0.08);
        border-radius: 4px;

        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }

  .foot {
    margin-top: 0.15rem;
    display: flex;
    justify-content: center;
  }
}

.coupon {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .coupon_item {
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }

  .selected {
    background-color: #409eff;
    color: #fff;
  }
}
</style>
